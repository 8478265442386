import _ from "lodash";

export const BuyXPayYOfSame = async (product, pricingRule, setCart, cart, orderType, cartObj) => {
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const saleTypeValidation = (pricingRule?.cwrSaletypeId === null || orderType === undefined) ? true : pricingRule?.cwrSaletypeId === orderType?.cwrSaletype?.cwrSaletypeId;
  let excludingFlag = true;

  if (pricingRule.mPricingB2CCustomerSegments.length > 0) {
    const matchingCustomerSegment = pricingRule.mPricingB2CCustomerSegments.find((pc) => pc.cwrB2CCustomerSegmentId === cartObj.customer?.b2cCustomerSegment?.cwrB2CCustomerSegmentId);
    
    if ((pricingRule.excludeB2CSegment === "Y" && matchingCustomerSegment) || (pricingRule.excludeB2CSegment !== "Y" && !matchingCustomerSegment)) {
      excludingFlag = false;
    }
  }

  if (pricingRule.mPricingB2CCustomers.length > 0) {
    const matchingCustomer = pricingRule.mPricingB2CCustomers.find((pc) => pc.b2cCustomerId === cartObj.customer.cwrCustomerId);
    
    if ((pricingRule.excludeB2CCustomers === "Y" && matchingCustomer) || (pricingRule.excludeB2CCustomers !== "Y" && !matchingCustomer)) {
      excludingFlag = false;
    }
  }

  if (pricingRule.mPricingPcategories.length > 0) {
    const matchingCategoy = pricingRule.mPricingPcategories.find((pc) => pc.mProductCategoryId === product.mProductCategoryId);
  
    if ((pricingRule.excludeProductCategories === "Y" && matchingCategoy) || (pricingRule.excludeProductCategories !== "Y" && !matchingCategoy)) {
      excludingFlag = false;
    }
  }

  if (pricingRule.mPricingBrands.length > 0) {
    const matchingBrand = pricingRule.mPricingBrands.find((pc) => pc.mBrandId === product.productBrandId);
  
    if ((pricingRule.excludeBrands === "Y" && matchingBrand) || (pricingRule.excludeBrands !== "Y" && !matchingBrand)) {
      excludingFlag = false;
    }
  }

  if (pricingRule.mPricingBUnits.length > 0) {
    excludingFlag = pricingRule.mPricingBUnits.some((pc) => pc.mBunitPricingId === tillData.tillAccess.csBunit.csBunitId);
  }
  

  if (pricingRule.mPricingXProducts.length > 0) {
    const matchingProductIndex = pricingRule.mPricingXProducts.findIndex((op) => op.mProductId === product.productId);
  
    if ((pricingRule.excludeProducts === "Y" && matchingProductIndex !== -1) || (pricingRule.excludeProducts !== "Y" && matchingProductIndex === -1)) {
      excludingFlag = false;
    }
  }

  let pricingRuleFlag = true;
  if(pricingRule.mPricingXProducts.length>0){
    pricingRule.mPricingXProducts.some((op) => {
      cartObj.items.map((product)=> {
        if(op.mProductId === product.productId && product.nextRule === "Y"){
          pricingRuleFlag = false;
        }
      })
    })
  }else{
    if(product.nextRule === "Y"){
      pricingRuleFlag = false;
    }
  }

  if (saleTypeValidation && excludingFlag && pricingRuleFlag) {
    let addedToCart = cartObj.items;
    let uniqData = _.uniqBy(cartObj.items, 'value');
    const offerProducts = pricingRule.mPricingXProducts;
    let matchedData = []
    let finalProducts = uniqData.filter((item, index) => {
      const ifExistis = offerProducts.findIndex((op) => op.mProductId === item.productId);
      if (ifExistis >= 0) {
        matchedData.push(item)
        if (parseFloat(item.weight) >= pricingRule.xQty && item.productId === offerProducts[ifExistis].mProductId) {
          return item;
        }
      }
    });

    const leastRealPriceIndex = matchedData.reduce((minIndex, item, currentIndex) => {
      if (item.realPrice < matchedData[minIndex].realPrice) {
        return currentIndex;
      } else {
        return minIndex;
      }
    }, 0);

    if (finalProducts.length>0 && matchedData.length === 1) {
      const discountAmt = matchedData[leastRealPriceIndex].realPrice * (pricingRule.xQty - pricingRule.yQty);
      const sp = parseFloat(matchedData[leastRealPriceIndex].realPrice) - discountAmt;
      const mrp = parseFloat(matchedData[leastRealPriceIndex].realPrice) * matchedData[leastRealPriceIndex].weight - discountAmt;
      const tax = mrp - mrp / (1 + matchedData[leastRealPriceIndex].taxRate / 100);
      matchedData[leastRealPriceIndex].taxAmount = tax;
      matchedData[leastRealPriceIndex].offer = "Y";
      matchedData[leastRealPriceIndex].nettotal = mrp;
      matchedData[leastRealPriceIndex].promotionProduct = "Y";
      matchedData[leastRealPriceIndex].discount = discountAmt;
      matchedData[leastRealPriceIndex].discountName = pricingRule.printedName;
      matchedData[leastRealPriceIndex].mPricingruleId = pricingRule.mPricingrulesId;
      let filterInd = addedToCart.findIndex((item, index) => matchedData[leastRealPriceIndex].productId === item.productId);
      if(filterInd >= 0){
        addedToCart[filterInd] = matchedData[leastRealPriceIndex];
      }
    }else if(finalProducts.length>0 && matchedData.length > 1){
      let discountProductQty = pricingRule.xQty - pricingRule.yQty;
      let remaingQty
      if(matchedData[leastRealPriceIndex].weight <= discountProductQty){
        remaingQty = discountProductQty - matchedData[leastRealPriceIndex].weight
        const discountAmt = matchedData[leastRealPriceIndex].realPrice;
        const sp = parseFloat(matchedData[leastRealPriceIndex].realPrice) - discountAmt;
        const mrp = 0;
        const tax = mrp - mrp / (1 + matchedData[leastRealPriceIndex].taxRate / 100);
        matchedData[leastRealPriceIndex].taxAmount = tax;
        matchedData[leastRealPriceIndex].offer = "Y";
        matchedData[leastRealPriceIndex].nettotal = mrp;
        matchedData[leastRealPriceIndex].promotionProduct = "Y";
        matchedData[leastRealPriceIndex].discount = discountAmt;
        matchedData[leastRealPriceIndex].discountName = pricingRule.printedName;
        matchedData[leastRealPriceIndex].mPricingruleId = pricingRule.mPricingrulesId;
        let filterInd = addedToCart.findIndex((item, index) => matchedData[leastRealPriceIndex].productId === item.productId);
        if(filterInd >= 0){
          addedToCart[filterInd] = matchedData[leastRealPriceIndex];
          delete matchedData[leastRealPriceIndex];
        }
      }
      if(remaingQty >= 1){
        const leastRealPriceIndex = matchedData.reduce((minIndex, item, currentIndex) => {
          if (item.realPrice < matchedData[minIndex].realPrice) {
            return currentIndex;
          } else {
            return minIndex;
          }
        }, 0);
        const discountAmt = matchedData[leastRealPriceIndex].realPrice * remaingQty;
        const sp = parseFloat(matchedData[leastRealPriceIndex].realPrice) - discountAmt;
        const mrp = parseFloat(matchedData[leastRealPriceIndex].realPrice) * matchedData[leastRealPriceIndex].weight - discountAmt;
        const tax = mrp - mrp / (1 + matchedData[leastRealPriceIndex].taxRate / 100);
        matchedData[leastRealPriceIndex].taxAmount = tax;
        matchedData[leastRealPriceIndex].offer = "Y";
        matchedData[leastRealPriceIndex].nettotal = mrp;
        matchedData[leastRealPriceIndex].promotionProduct = "Y";
        matchedData[leastRealPriceIndex].discount = discountAmt;
        matchedData[leastRealPriceIndex].discountName = pricingRule.printedName;
        matchedData[leastRealPriceIndex].mPricingruleId = pricingRule.mPricingrulesId;
        let filterInd = addedToCart.findIndex((item, index) => matchedData[leastRealPriceIndex].productId === item.productId);
        if(filterInd >= 0){
          addedToCart[filterInd] = matchedData[leastRealPriceIndex];
        }
      }

    }else{
      const mrp = parseFloat(matchedData[leastRealPriceIndex].realPrice) * matchedData[leastRealPriceIndex].weight;
      const tax = mrp - mrp / (1 + matchedData[leastRealPriceIndex].taxRate / 100);
      matchedData[leastRealPriceIndex].taxAmount = tax;
      matchedData[leastRealPriceIndex].nettotal = mrp;
      matchedData[leastRealPriceIndex].promotionProduct = "N";
      matchedData[leastRealPriceIndex].discount = 0;
      matchedData[leastRealPriceIndex].discountName = "";
      matchedData[leastRealPriceIndex].mPricingruleId = null;
      let filterInd = addedToCart.findIndex((item, index) => matchedData[leastRealPriceIndex].productId === item.productId);
      if(filterInd >= 0){
        addedToCart[filterInd] = matchedData[leastRealPriceIndex];
      }
    }

    let totalTax = 0;
    let totalPrice = 0;
    let totalItemsQty = 0;
    let totalDiscounts = 0;
    for (let i = 0; i < addedToCart.length; i += 1) {
      totalPrice += addedToCart[i].nettotal;
      totalItemsQty += addedToCart[i].weight;
      totalTax += addedToCart[i].taxAmount;
      totalDiscounts += addedToCart[i].discount;
      addedToCart[i].key = i;
    }

    const roundOffValue = Math.round(totalPrice);
    const totalRoundOff = totalPrice - roundOffValue;
    totalPrice = roundOffValue;

    setCart({
      ...cartObj,
      items: [...addedToCart],
      total: totalPrice,
      tax: totalTax,
      discount: totalDiscounts,
      totalQty: totalItemsQty,
      roundOff: totalRoundOff,
    });
  }

}