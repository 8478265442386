export const flatDiscount = async (product, pricingRule, setCart, cart, orderType, cartObj, cartRef, iscoupon,couponInput,uniqReferenceId,mPricingCouponId, removeDiscounts) => {
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const saleTypeValidation = pricingRule?.cwrSaletypeId === null || orderType === undefined ? true : pricingRule?.cwrSaletypeId === orderType?.cwrSaletype?.cwrSaletypeId;
  let hasChanges = false;
  
  // Iterate through each item in the cart
  cartObj.items.map((item) => {
    let excludingFlag = true;

    // Check if there are any changes
      const matchingCategory = pricingRule.mPricingPcategories.find((pc) => pc.mProductCategoryId === item.mProductCategoryId);
      if ((pricingRule.excludeProductCategories === "Y" && matchingCategory) || (pricingRule.excludeProductCategories !== "Y" && !matchingCategory) || (pricingRule.excludeProductCategories === "N" && pricingRule.mPricingPcategories.length === 0)) {
        excludingFlag = false;
      }

      const matchingBrand = pricingRule.mPricingBrands.find((pc) => pc.mBrandId === item.productBrandId);
      if ((pricingRule.excludeBrands === "Y" && matchingBrand) || (pricingRule.excludeBrands !== "Y" && !matchingBrand) || (pricingRule.excludeBrands === "N" && pricingRule.mPricingBrands.length === 0)) {
        excludingFlag = false;
      }

    if (pricingRule.mPricingBUnits.length > 0) {
      excludingFlag = pricingRule.mPricingBUnits.some((pc) => pc.mBunitPricingId === tillData.tillAccess.csBunit.csBunitId);
    }

      const matchingProductIndex = pricingRule.mPricingXProducts.findIndex((op) => op.mProductId === item.productId);
      if ((pricingRule.excludeProducts === "Y" && matchingProductIndex !== -1) || (pricingRule.excludeProducts !== "Y" && matchingProductIndex === -1) || (pricingRule.excludeProducts === "N" && pricingRule.mPricingXProducts.length === 0)) {
        excludingFlag = false;
      }

    let pricingRuleFlag = true;

    // Apply additional logic for pricingRuleFlag based on your requirements

    if (saleTypeValidation && excludingFlag && pricingRuleFlag && (product.productId === item.productId || iscoupon || removeDiscounts)) {
      const index = cartObj.items.findIndex((p) => p.productId === item.productId && p.upc === item.upc && p.mBatchId === item.mBatchId);
      pricingRule.minimumQty = pricingRule.minimumQty ? pricingRule.minimumQty : 0;

      if (index >= 0) {
        if (Math.abs(cartObj.items[index].weight) >= pricingRule.minimumQty) {
          const discountAmt = pricingRule.amountDiscount;
          const sp = parseFloat(cartObj.items[index].realPrice) - discountAmt;
          const mrp = parseFloat(sp) * cartObj.items[index].weight;
          const tax = mrp - mrp / (1 + cartObj.items[index].taxRate / 100);

          // Check if any changes are made
          if (
            sp !== cartObj.items[index].salePrice ||
            tax !== cartObj.items[index].taxAmount ||
            mrp !== cartObj.items[index].nettotal ||
            discountAmt !== cartObj.items[index].discount
          ) {
            hasChanges = true;
          }

          cartObj.items[index].salePrice = sp;
          cartObj.items[index].taxAmount = tax;
          cartObj.items[index].nettotal = mrp;
          cartObj.items[index].discount = discountAmt * cartObj.items[index].weight;
          cartObj.items[index].discountName = pricingRule.printedName;
          cartObj.items[index].type = pricingRule.type;
          cartObj.items[index].mPricingruleId = pricingRule.mPricingrulesId;
          cartObj.items[index].nextRule = pricingRule.nextRule;
          cartObj.items[index].iscoupon = iscoupon;
          cartObj.items[index].couponInput = couponInput;
          cartObj.items[index].referenceId = uniqReferenceId;
          cartObj.items[index].mPricingCouponId = mPricingCouponId;
        } else {
          const mrp = parseFloat(cartObj.items[index].realPrice) * cartObj.items[index].weight;
          const tax = mrp - mrp / (1 + cartObj.items[index].taxRate / 100);

          // Check if any changes are made
          if (tax !== cartObj.items[index].taxAmount || mrp !== cartObj.items[index].nettotal || cartObj.items[index].discount !== 0 || cartObj.items[index].discountName !== "") {
            hasChanges = true;
          }

          cartObj.items[index].taxAmount = tax;
          cartObj.items[index].nettotal = mrp;
          cartObj.items[index].discount = 0;
          cartObj.items[index].discountName = "";
          cartObj.items[index].mPricingruleId = null;
          delete cartObj.items[index].nextRule;
        }
      }
    }

    return item;
  });

  // Check if any changes are made and return the updated cart object
  if (hasChanges) {
    let couponsData = cart.couponInput?.length >0 ? [...cart.couponInput] : [];
    if(!couponsData.some(coupon => coupon.couponCode === couponInput) && couponInput !== undefined && couponInput !== null){
      couponsData.push({couponCode:couponInput,referenceId:uniqReferenceId,mPricingCouponId: mPricingCouponId})
    }
    cartObj.iscoupon = iscoupon;
    cartObj.couponInput = couponsData;
    setCart(cartObj);
    localStorage.setItem("cartObj",JSON.stringify(cartObj));
    return cartObj;
  }
};
