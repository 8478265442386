import _ from "lodash";

export const BuyXPayYOfDifferent = async (product, pricingRule, setCart, cart, orderType, cartObj) => {
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const saleTypeValidation = (pricingRule?.cwrSaletypeId === null || orderType === undefined) ? true : pricingRule?.cwrSaletypeId === orderType?.cwrSaletype?.cwrSaletypeId;
  let excludingFlag = true;

  if (pricingRule.mPricingB2CCustomerSegments.length > 0) {
    const matchingCustomerSegment = pricingRule.mPricingB2CCustomerSegments.find((pc) => pc.cwrB2CCustomerSegmentId === cartObj.customer?.b2cCustomerSegment?.cwrB2CCustomerSegmentId);
    
    if ((pricingRule.excludeB2CSegment === "Y" && matchingCustomerSegment) || (pricingRule.excludeB2CSegment !== "Y" && !matchingCustomerSegment)) {
      excludingFlag = false;
    }
  }

  if (pricingRule.mPricingB2CCustomers.length > 0) {
    const matchingCustomer = pricingRule.mPricingB2CCustomers.find((pc) => pc.b2cCustomerId === cartObj.customer.cwrCustomerId);
    
    if ((pricingRule.excludeB2CCustomers === "Y" && matchingCustomer) || (pricingRule.excludeB2CCustomers !== "Y" && !matchingCustomer)) {
      excludingFlag = false;
    }
  }

  if (pricingRule.mPricingPcategories.length > 0) {
    const matchingCategoy = pricingRule.mPricingPcategories.find((pc) => pc.mProductCategoryId === product.mProductCategoryId);
  
    if ((pricingRule.excludeProductCategories === "Y" && matchingCategoy) || (pricingRule.excludeProductCategories !== "Y" && !matchingCategoy)) {
      excludingFlag = false;
    }
  }

  if (pricingRule.mPricingBrands.length > 0) {
    const matchingBrand = pricingRule.mPricingBrands.find((pc) => pc.mBrandId === product.productBrandId);
  
    if ((pricingRule.excludeBrands === "Y" && matchingBrand) || (pricingRule.excludeBrands !== "Y" && !matchingBrand)) {
      excludingFlag = false;
    }
  }

  if (pricingRule.mPricingBUnits.length > 0) {
    excludingFlag = pricingRule.mPricingBUnits.some((pc) => pc.mBunitPricingId === tillData.tillAccess.csBunit.csBunitId);
  }
  

  if (pricingRule.mPricingXProducts.length > 0) {
    const matchingProductIndex = pricingRule.mPricingXProducts.findIndex((op) => op.mProductId === product.productId);
  
    if ((pricingRule.excludeProducts === "Y" && matchingProductIndex !== -1) || (pricingRule.excludeProducts !== "Y" && matchingProductIndex === -1)) {
      excludingFlag = false;
    }
  }

  let pricingRuleFlag = true;
  if(pricingRule.mPricingXProducts.length>0){
    pricingRule.mPricingXProducts.some((op) => {
      cartObj.items.map((product)=> {
        if(op.mProductId === product.productId && product.nextRule === "Y"){
          pricingRuleFlag = false;
        }
      })
    })
  }else{
    if(product.nextRule === "Y"){
      pricingRuleFlag = false;
    }
  }

  if (saleTypeValidation && excludingFlag && pricingRuleFlag) {
    let addedToCart = cartObj.items;
    let uniqData = _.uniqBy(cartObj.items, 'value');
    const offerProducts = pricingRule.mPricingXProducts;
    const freeProducts = pricingRule.mPricingXProducts.filter((prod) => prod.isFree === "Y");
    const conditionalProducts = pricingRule.mPricingXProducts.filter((prod)=> prod.isFree === "N");
    const prodIndexes = [];
    let freeProductIndex;
    let minimumSet = 1;
    let produtsData = [];

    uniqData.map((item, index) => {
      const ifExistis = offerProducts.findIndex((op) => op.mProductId === item.productId);
      const xProduct = conditionalProducts.findIndex((op) => op.mProductId === item.productId);

      if(xProduct >= 0){
        if(item.productId === conditionalProducts[xProduct].mProductId && item.weight >= conditionalProducts[xProduct].quantity){
          produtsData.push(index);
          minimumSet = Math.floor(item.weight/conditionalProducts[xProduct].quantity);
        }
      }

      if (ifExistis >= 0) {
        if (item.productId === freeProducts[0]?.mProductId) {
          freeProductIndex = index;
        }
        if (parseFloat(item.weight) <= minimumSet) {
          minimumSet = parseFloat(item.weight);
        }
        prodIndexes.push(index);
      }
      return null;
    });


    if (prodIndexes.length === pricingRule.mPricingXProducts.length && conditionalProducts.length === produtsData.length) {
      if(addedToCart[freeProductIndex].weight <= minimumSet){
        // addedToCart[freeProductIndex].weight = addedToCart[freeProductIndex].weight;
      }else{
      // let newProduct = {...product};
      // const ifExistis = addedToCart.findIndex((op) => op.productId === product.productId && op.offer === "N");
      // if(ifExistis >0){
      //   addedToCart[ifExistis].weight = addedToCart[ifExistis].weight + addedToCart[freeProductIndex].weight - minimumSet;
      //   const sp = addedToCart[ifExistis].realPrice;
      //   const mrp = sp * addedToCart[ifExistis].weight;
      //   const tax = mrp - mrp / (1 + addedToCart[ifExistis].taxRate / 100);
      //   addedToCart[ifExistis].salePrice = sp;
      //   addedToCart[ifExistis].taxAmount = tax;
      //   addedToCart[ifExistis].offer = "N";
      //   addedToCart[ifExistis].nettotal = mrp;
      //   addedToCart[ifExistis].discount = 0;
      //   addedToCart[ifExistis].discountName = "";
      // }else {
        // const sp = parseFloat(product.realPrice);
        // const mrp = parseFloat(product.realPrice) * (addedToCart[freeProductIndex].weight - minimumSet);
        // const tax = mrp - mrp / (1 + product.taxRate / 100);
        // newProduct.weight = addedToCart[freeProductIndex].weight - minimumSet;
        // newProduct.taxAmount = tax;
        // newProduct.salePrice = sp;
        // newProduct.nettotal = sp * newProduct.weight;
        // newProduct.discount = 0;
        // newProduct.discountName = "";
        // newProduct.mPricingruleId = null;
        // newProduct.offer = "N";
        // addedToCart = [...addedToCart,newProduct]
      // }
      addedToCart[freeProductIndex].weight = minimumSet;
      }
      
      const sp = 0;
      const mrp = sp * addedToCart[freeProductIndex].weight;
      const tax = mrp - mrp / (1 + addedToCart[freeProductIndex].taxRate / 100);
      addedToCart[freeProductIndex].salePrice = sp;
      addedToCart[freeProductIndex].taxAmount = tax;
      addedToCart[freeProductIndex].offer = "Y";
      addedToCart[freeProductIndex].nettotal = mrp;
      addedToCart[freeProductIndex].promotionProduct = "Y";
      // addedToCart[freeProductIndex].baseProduct = [ ]
      addedToCart[freeProductIndex].discount = addedToCart[freeProductIndex].realPrice * addedToCart[freeProductIndex].weight;
      addedToCart[freeProductIndex].discountName = pricingRule.printedName;
      addedToCart[freeProductIndex].mPricingruleId = pricingRule.mPricingrulesId;
      // message.success(pricingRule.printedName);
    } else {
      if (freeProductIndex !== undefined) {
        const mrp = parseFloat(addedToCart[freeProductIndex].realPrice) * addedToCart[freeProductIndex].weight;
        const tax = mrp - mrp / (1 + addedToCart[freeProductIndex].taxRate / 100);
        addedToCart[freeProductIndex].taxAmount = tax;
        addedToCart[freeProductIndex].nettotal = mrp;
        addedToCart[freeProductIndex].promotionProduct = "N";
        addedToCart[freeProductIndex].discount = 0;
        addedToCart[freeProductIndex].discountName = "";
        addedToCart[freeProductIndex].mPricingruleId = null;
      }
    }

    let totalTax = 0;
    let totalPrice = 0;
    let totalItemsQty = 0;
    let totalDiscounts = 0;
    for (let i = 0; i < addedToCart.length; i += 1) {
      totalPrice += addedToCart[i].nettotal;
      totalItemsQty += addedToCart[i].weight;
      totalTax += addedToCart[i].taxAmount;
      totalDiscounts += addedToCart[i].discount;
      addedToCart[i].key = i;
    }

    const roundOffValue = Math.round(totalPrice);
    const totalRoundOff = totalPrice - roundOffValue;
    totalPrice = roundOffValue;

    setCart({
      ...cartObj,
      items: [...addedToCart],
      total: totalPrice,
      tax: totalTax,
      discount: totalDiscounts,
      totalQty: totalItemsQty,
      roundOff: totalRoundOff,
    });
  }

}